import React, { useRef, useState, useEffect } from "react";
import "./hero.css";
import gsap from "gsap";

import johanna from "../assets/johanna2.webp";
import { Abba } from "../animations/Abba";
import { Ikea } from "../animations/Ikea";
import { Viking } from "../animations/Viking";
import { Fika } from "../animations/Fika";

export const Hero = () => {
  const johannaRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.unobserve(entry.target);
        }
      });
    };

    let options = {
      root: null,
      threshold: 1, // triggers callback more often
    };

    // check if it's a mobile device and modify options
    if (window.innerWidth <= 500) {
      options.rootMargin = "200px"; // increase rootMargin
    } else {
      options.rootMargin = "100px";
    }

    const observer = new IntersectionObserver(handleIntersect, options);

    const johannaNode = johannaRef.current;

    if (johannaNode) {
      observer.observe(johannaNode);
    }

    return () => {
      if (johannaNode) {
        observer.unobserve(johannaNode);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      const tl = gsap.timeline();

      tl.to(johannaRef.current.children, {
        opacity: 1,
        duration: 0.2,
        ease: "sine.in",
        stagger: {
          amount: 0.4,
          grid: [1, 1],
          from: "edges",
        },
      });
      return () => {
        tl.reverse();
      };
    }
  }, [isIntersecting]);

  return (
    <>
      <article className="home__hero">
        <div className="hero__content">
          <img
            className="hero__image"
            src={johanna}
            alt="Hero"
            loading="lazy"
            decoding="async"
            width="100%"
            height="auto"
          />
          <div className="hero__grid">
            <div className="hero__copy">
              <div>
                <p>Hi. I am</p>
              </div>
            </div>
            <div className="hero__copy-title">
              <h1 ref={johannaRef} className="h1__title">
                <span>J</span>
                <span>o</span>
                <span>h</span>
                <span>a</span>
                <span>n</span>
                <span>n</span>
                <span>a.</span>
              </h1>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 8"
              className="hero__arrow"
            >
              <path
                d="M293.15 274.51h34.93v9.43h-34.93z"
                fill="none"
                transform="matrix(.58 0 0 .81 -169.47 -223.3)"
              />
              <path
                d="M254.73 285.01v-2.08h64.51l-9.38-6.76H314l10.83 7.8-10.83 7.8h-4.13l9.38-6.75h-64.51Z"
                transform="matrix(.26 0 0 .34 -65.56 -93.65)"
              />
            </svg>
            <div className="hero__copy-info">
              <p>Front-end developer with</p>
              <p>a penchant for animations</p> <p>and playful interactions.</p>
            </div>

            <div className="hero__copy__based-content">
              <div className="hero__copy-line">
                <span></span>
              </div>
              <div className="hero__based">
                <p>Based in the land of</p>
                <div className="hero__based-content">
                  <Ikea />
                  <Abba />
                  <Viking />
                  <Fika />.
                  <p className="hero__based-show">
                    Enough with the clues.&nbsp;
                    <a href="https://youtu.be/vfQU6pI51ww">Just show me</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
