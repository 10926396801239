import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export const Viking = () => {
  const vikingRef = useRef(null);
  const masterTimelineRef = useRef(null);

  useEffect(() => {
    masterTimelineRef.current = gsap.timeline({ paused: true });

    masterTimelineRef.current
      .set(
        [
          "#left_m",
          "#right_m",
          "#Beard",
          "#Left_arm",
          "#Right_arm",
          "#Axe",
          "#EyesViking circle",
          "#LeftEyebrow",
          "#RightEyebrow",
        ],
        { clearProps: "all" }
      )
      .addLabel("armsAndEyesStart")
      .to(
        "#Left_arm",
        {
          duration: 1,
          rotate: -17,
          transformOrigin: "45% 0%",
          ease: "power2.inOut",
        },
        "armsAndEyesStart"
      )
      .to(
        "#Right_arm",
        {
          duration: 1,
          rotate: -75,
          transformOrigin: "35% 0%",
          ease: "power2.inOut",
        },
        "armsAndEyesStart"
      )
      .to(
        "#Axe",
        {
          duration: 1,
          transformOrigin: "100% 80%",
          y: -70,
          x: 30,
          ease: "power2.inOut",
        },
        "armsAndEyesStart"
      )
      .to(
        "#EyesViking circle:nth-child(1)",
        {
          duration: 0.5,
          attr: { cx: "+=2", cy: "-=1" },
          ease: "power2.inOut",
        },
        "<"
      )
      .to(
        "#EyesViking circle:nth-child(2)",
        {
          duration: 0.5,
          attr: { cx: "+=2", cy: "-=1" },
          ease: "power2.inOut",
        },
        "<"
      )
      .to(
        "#LeftEyebrow",
        {
          duration: 0.5,
          rotate: 10,
          transformOrigin: "top center",
        },
        "<"
      )
      .to(
        "#RightEyebrow",
        {
          duration: 0.5,
          rotate: -10,
          transformOrigin: "top center",
          y: "-=4",
        },
        "<"
      )
      .addLabel("beardWindStart")
      .to(
        "#Beard",
        {
          skewX: "4deg",
          yoyo: true,
          repeat: 5,
          duration: 0.4,
        },
        "beardWindStart"
      )
      .addLabel("resetStart", "beardWindStart+=2.5")
      .to(
        "#Left_arm",
        {
          duration: 1,
          rotate: 0,
          transformOrigin: "45% 0%",
          ease: "power2.inOut",
        },
        "resetStart"
      )
      .to(
        "#Right_arm",
        {
          duration: 1,
          rotate: 0,
          transformOrigin: "35% 0%",
          ease: "power2.inOut",
        },
        "resetStart"
      )
      .to(
        "#Axe",
        {
          duration: 1,
          rotate: 0,
          transformOrigin: "100% 80%",
          y: 0,
          x: 0,
          ease: "power2.inOut",
        },
        "resetStart"
      )
      .to(
        "#EyesViking circle:nth-child(1)",
        {
          duration: 1,
          attr: {
            cx: 67.97,
            cy: 84.77,
          },
          ease: "power2.inOut",
        },
        "resetStart"
      )
      .to(
        "#EyesViking circle:nth-child(2)",
        {
          duration: 1,
          attr: {
            cx: 137.54,
            cy: 84.77,
          },
          ease: "power2.inOut",
        },
        "resetStart"
      )
      .to(
        "#LeftEyebrow",
        {
          duration: 1,
          rotate: 0,
        },
        "resetStart"
      )
      .to(
        "#RightEyebrow",
        {
          duration: 1,
          rotate: 0,
          y: 0,
        },
        "resetStart"
      );

    return () => {
      masterTimelineRef.current.kill();
    };
  }, []);

  useEffect(() => {
    const handleEnter = () => {
      masterTimelineRef.current.play();
    };

    const handleLeave = () => {
      masterTimelineRef.current.pause();
    };

    const appElement = vikingRef.current;

    appElement.addEventListener("pointerenter", handleEnter);
    appElement.addEventListener("pointerleave", handleLeave);

    return () => {
      appElement.removeEventListener("pointerenter", handleEnter);
      appElement.removeEventListener("pointerleave", handleLeave);
    };
  }, []);

  return (
    <span className="hero__based-content-overlay" ref={vikingRef}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="viking"
          viewBox="0 0 280.03 283.51"
          role="img"
          alt=""
        >
          <g id="Axe">
            <path
              d="m172.07 223.61-1.52-.62a3.93 3.93 0 0 1-2.15-5.13l37.42-91.22a3.93 3.93 0 0 1 5.13-2.15l1.52.62a3.93 3.93 0 0 1 2.15 5.13l-37.42 91.22a3.93 3.93 0 0 1-5.13 2.15z"
              className="cls-1"
            />
            <path
              d="M222.92 190.17c.77-14.21-4.94-27-19.41-32.82l5.77-14.07c14.21 6.43 26.12 4.11 35.73-6.96l-22.09 53.85z"
              className="cls-1"
            />
            <path
              d="M222.9 190.15 245 136.3l5.98 2.45-22.09 53.86z"
              className="cls-1"
            />
          </g>
          <path
            id="Left_arm"
            d="m76.15 159.37-45.72 45.72a12.22 12.22 0 0 1-17.28 0h0a12.22 12.22 0 0 1 0-17.28l45.72-45.72a12.22 12.22 0 0 1 17.28 0h0a12.2 12.2 0 0 1 0 17.28z"
            className="cls-1"
          />
          <path
            id="Right_arm"
            d="m146.64 140.91 45.72 45.72a12.22 12.22 0 0 1 0 17.28h0a12.22 12.22 0 0 1-17.28 0l-45.72-45.72a12.22 12.22 0 0 1 0-17.28h0a12.22 12.22 0 0 1 17.28 0z"
            className="cls-1"
          />
          <path
            id="Left_shoe"
            d="M54.74 237.07v31.04l-12.49 13.28h47.8v-44.32z"
            className="cls-1"
          />
          <path
            id="Right_shoe"
            d="M150.78 237.07v31.04l12.48 13.28h-47.8v-44.32z"
            className="cls-1"
          />
          <path
            id="TorsoViking"
            d="M130.29 135.85H75.22a18.17 18.17 0 0 0-18.1 16.57L45.58 239.2c0 18.29 114.35 18.29 114.35 0l-11.54-86.77a18.17 18.17 0 0 0-18.1-16.58z"
            className="cls-1"
          />
          <ellipse
            id="HeadViking"
            cx="102.76"
            cy="91.23"
            className="cls-1"
            rx="68.57"
            ry="54.26"
          />
          <g id="EyesViking">
            <circle cx="67.97" cy="84.77" r="3.32" />
            <circle cx="137.54" cy="84.77" r="3.32" />
          </g>
          <rect
            id="LeftEyebrow"
            x="60"
            y="75"
            width="15"
            height="3"
            fill="black"
          />
          <rect
            id="RightEyebrow"
            x="130"
            y="75"
            width="15"
            height="3"
            fill="black"
          />
          <g id="Beard">
            <path
              d="M53.85 131.76s8.11 39.15 39.5 58.47c0 0 2.59-9.83 6.21-11.04 3.62-1.21 8.45 11.73 8.8 20.87.34 9.14 36.56-30.18 33.63-68.3s-88.14 0-88.14 0z"
              className="cls-1"
            />
            <path
              d="M101.36 119.99a19.1 19.1 0 0 0-19.11-19.11c-6.6 0-12.62 1.85-19.71 8.38-7.09 6.53-21.12 10.84-29.94 8.86 0 0 2.93 23.69 44.9 21.82a49.3 49.3 0 0 0 7.99-1.12c8.05-2.05 15.87-9.38 15.87-18.83z"
              className="cls-1"
            />
            <path
              d="M104.15 119.99a19.1 19.1 0 0 1 19.11-19.11c6.6 0 12.62 1.85 19.71 8.38 7.09 6.53 21.12 10.84 29.94 8.86 0 0-2.93 23.69-44.9 21.82a49.3 49.3 0 0 1-7.99-1.12c-8.04-2.05-15.87-9.38-15.87-18.83z"
              className="cls-1"
            />
          </g>
          <path
            id="NoseViking"
            d="M105.9 115.57h-6.29c-4.68 0-8.31-4.63-7.79-9.91l1.76-17.78c.45-4.51 3.8-7.92 7.79-7.92h2.78c3.99 0 7.34 3.41 7.79 7.92l1.76 17.78c.51 5.29-3.12 9.91-7.8 9.91z"
            className="cls-1"
          />
          <g id="Belt">
            <path
              d="M48.81 221.87c37.22 14.39 73.14 13.9 107.89 0"
              className="cls-1"
            />
            <path
              d="M51.28 209.96c37.22 14.39 68.2 13.9 102.95 0"
              className="cls-1"
            />
          </g>
          <g id="Helm">
            <path
              d="M166.38 63.03s33.15 2.04 35.87-24.3c2.36-22.84-13.11-33.26-27.87-36.67 0 0 33.16 33.37-15.59 38.19l-6.93-2.99v24.53l14.52 1.24z"
              className="cls-1"
            />
            <path
              d="M39.13 63.03S5.98 65.07 3.26 38.73C.9 15.89 16.37 5.47 31.13 2.06c0 0-33.16 33.37 15.59 38.19l6.93-2.99v24.53l-14.52 1.24z"
              className="cls-1"
            />
            <path
              d="M166.18 49.64H39.34c0-22.62 63.42-40.96 63.42-40.96s63.42 18.34 63.42 40.96z"
              className="cls-1"
            />
            <path d="M35.27 49.64h134.98v19.63H35.27z" className="cls-1" />
          </g>
        </svg>
      </div>
      <span>vikings</span> and&nbsp;
    </span>
  );
};
