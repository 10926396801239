import React, { useRef } from "react";
import { useIntersection } from "react-use";
import gsap from "gsap";

import dino from "../assets/dino.webp";
import "./footer.css";

export const Footer = () => {
  const inView = useRef(null);

  const intersection = useIntersection(inView, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });

  const dinoPop = () => {
    const timeline = gsap.timeline();
    timeline.to(
      ".dino_animation",
      {
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
      },
      "+0.5"
    );
    timeline.fromTo(
      ".footer__svg",
      {
        x: "random(-1, -2)",
        y: "random(-1, -3)",
      },
      {
        x: "random(1, 2)",
        y: "random(1, 3)",
        duration: 0.1,
        ease: "bounce.inout",
        yoyo: true,
        repeat: 8,
      },
      "-=1"
    );
  };

  if (intersection && intersection.intersectionRatio > 0.5) {
    dinoPop();
  }

  return (
    <>
      <footer>
        <div className="inner">
          <div className="footer__contact">
            <p className="small__title w nm">say hello</p>
            <span></span>
            <a
              href="mailto:"
              onClick={(e) => {
                window.location.href = "mailto:blomjoha@gmail.com";
                e.preventDefault();
              }}
              className="menu__link-footer"
            >
              email
            </a>
            <a
              href="https://github.com/idsintehittapa"
              className="menu__link-footer"
            >
              git
            </a>
            <a
              href="https://www.linkedin.com/in/johanna-blom-2419a181/"
              className="menu__link-footer"
            >
              linkedin
            </a>
            <a
              href="https://twitter.com/johanna__blom"
              className="menu__link-footer"
            >
              twitter
            </a>
          </div>
          <div className="footer__name">
            <p>Johanna Blom</p>
            <p>front.end dev</p>
          </div>
          <div className="footer__made-info">
            <p>Made in Stockholm, Sweden.</p>
            <p>Code / Johanna Blom</p>
            <p>
              Design /&nbsp;
              <a href="https://www.prvrt.se">Rodrigo Ribeiro</a>
            </p>
            <p>
              Vectors /&nbsp;
              <a href="https://www.oliviako.com">Olivia Kozuchowska</a>
            </p>
          </div>
        </div>
        <div className="dino_animation" ref={inView}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 426 459"
            className="footer__svg"
          >
            <path
              d="M295.5 613.37h465.77v471.25H295.5z"
              fill="none"
              transform="matrix(.91 0 0 .97 -269.96 -597.24)"
            />
            <path d="M92.63 66.5C169.94 11.52 194 9.52 257.77 14.46c26.3 2.04 70.04 30.48 72.7 32.29 42.84 29.01 82.43 72.52 83.52 112.47.66 23.99 1.07 83.81-5.88 98.03-41.65 85.28-96.03 135.49-130.48 145.22-27.45 7.76 26.47 63.07-11.54 38.12-8.19-5.38-34.64-26.74-39.14-32.06-.43-.5-44.28 13.86-91.97-10.93-57.9-30.1-64.36-47.48-79.3-68.85C4.58 255.68 1.2 220.63 33.24 144.28c4.4-10.48 39.97-63.99 59.38-77.78Z" />
            <path d="M224.36 417.22c-12.77 2.68-51.54 9.07-93.07-12.51-60-31.2-66.69-49.22-82.17-71.38-26.54-37.95-40.43-66.14-43.78-94.65-3.36-28.6 3.83-57.7 20.54-97.49 4.6-10.95 41.84-66.8 62.11-81.21 79.75-56.7 104.64-58.6 170.4-53.5 27.65 2.15 73.77 31.75 76.56 33.65 26.58 18 51.9 41.45 68.2 66.2 11.38 17.33 18.37 35.34 18.84 52.67.47 17.01.8 51.8-1.45 76.08-1.07 11.54-2.9 20.9-5.24 25.69-43.03 88.1-99.9 139.35-135.5 149.4-3.24.92-2.48 3.93-1.8 6.86 1.64 7 5.85 14.77 8.06 20.09 1.97 4.77 2.35 8.67 1.87 10.74-.86 3.65-3.08 5.94-6.74 6.85a11.7 11.7 0 0 1-6.49-.39c-2.57-.81-6.76-2.95-13-7.04-7.27-4.78-28.3-21.68-37.34-30.06Zm41.33 13.28a67.74 67.74 0 0 1-3.27-9.84c-1.57-6.73-1.14-12.97 1.69-17.68 2.15-3.59 5.66-6.6 11.34-8.2 33.32-9.42 85.2-58.58 125.47-141.03 1.83-3.75 2.85-11.13 3.68-20.14 2.2-23.68 1.85-57.59 1.4-74.17-.4-14.62-6.62-29.7-16.23-44.31-15.21-23.13-38.96-44.94-63.79-61.76-2.52-1.7-43.88-29-68.83-30.93-61.75-4.79-85-2.66-159.89 50.58-18.53 13.18-52.43 64.35-56.63 74.37-15.35 36.55-22.49 63.15-19.4 89.43 3.1 26.37 16.45 52.23 41 87.35 14.4 20.6 20.64 37.34 76.44 66.34 40.35 20.97 77.78 12.09 85.16 10.45 5.89-1.3 8.94 2.07 9.23 2.4 3.62 4.29 22.21 19.43 32.63 27.14Z" />
            <path
              d="M.15-.75c.1 0 .17.04.2.14v.06c0 .09-.04.16-.13.2a2 2 0 0 1 .14.33C.36 0 .34 0 .33 0 .32 0 .29-.05.26-.15L.18-.33H.1V0L.08.02C.05.02.05 0 .05-.04v-.54c0-.1.01-.16.04-.16l.05-.01ZM.1-.7a2.57 2.57 0 0 0 0 .33h.03c.08 0 .14-.05.17-.14v-.05C.3-.63.27-.68.2-.7H.1Z"
              fill="#faf4f4"
              transform="matrix(86.58 -12.68 12.68 86.58 115.5 258.37)"
            />
            <path
              d="M.15-.75c.1 0 .17.04.2.14v.06c0 .09-.04.16-.13.2a2 2 0 0 1 .14.33C.36 0 .34 0 .33 0 .32 0 .29-.05.26-.15L.18-.33H.1V0L.08.02C.05.02.05 0 .05-.04v-.54c0-.1.01-.16.04-.16l.05-.01ZM.1-.7a2.57 2.57 0 0 0 0 .33h.03c.08 0 .14-.05.17-.14v-.05C.3-.63.27-.68.2-.7H.1Z"
              fill="#faf4f4"
              transform="matrix(87.38 4.51 -4.51 87.38 248.02 261.06)"
            />
            <path
              d="m.06-.07.01.02-.01.03H.01v-.05h.05Z"
              fill="#faf4f4"
              transform="matrix(87.38 4.51 -4.51 87.38 282.33 262.83)"
            />
            <path
              d="M.32-.77c.03 0 .05.01.06.03V-.54l.02.5-.02.02C.36-.02.35-.04.35-.07v-.24L.11-.3a2.2 2.2 0 0 0-.01.28L.08 0C.06 0 .06-.02.06-.07v-.05A5.83 5.83 0 0 1 .1-.68c.02-.04.04-.05.06-.06l.14-.03Zm-.2.43c.08 0 .16 0 .22-.02V-.65c0-.05 0-.07-.02-.07-.05 0-.1 0-.14.03a.2.2 0 0 0-.05.1l-.01.24Z"
              fill="#faf4f4"
              transform="matrix(70.83 0 0 70.83 161.2 262.87)"
            />
            <path
              d="M.05-.78h.01c.02 0 .02.04.02.1l.07.55C.19-.28.2-.36.23-.36h.02l.1.26C.37-.25.4-.46.42-.73l.03-.02a19.5 19.5 0 0 1-.05.6C.38-.05.36 0 .35 0 .33 0 .3-.04.29-.12a.6.6 0 0 0-.06-.15l-.05.25-.03.01C.13-.01.12-.04.1-.09a35.98 35.98 0 0 1-.06-.69Z"
              fill="#faf4f4"
              transform="matrix(83.33 0 0 83.33 201.13 255.93)"
            />
          </svg>
          <img
            src={dino}
            alt="dino"
            decoding="async"
            loading="lazy"
            width="auto"
            height="auto"
          />
        </div>
      </footer>
    </>
  );
};
