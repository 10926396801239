import Tarantino from "../assets/tarantino2.mp4";
import Liv from "../assets/LivStrom.mp4";
import WPJohanna from "../assets/johanna.mp4";
import IIoW from "../assets/IIoW.webp";

export const WorkPlayData = [
  {
    id: 1,
    title: "That's my name",
    video: WPJohanna,
    type: "video",
  },
  {
    id: 2,
    title: "codepen.io",
    video: Tarantino,
    type: "video",
    link: "https://codepen.io/collection/rxqObB?cursor=eyJwYWdlIjoxfQ==",
  },
  {
    id: 3,
    title: "... an animated comic",
    video: Liv,
    type: "video",
  },

  {
    id: 4,
    title: "I am working on it",
    image: IIoW,
    type: "image",
  },
];
