import React from "react";
import { Footer } from "../components/Footer";

import "./blog.css";
import { BlogThumb } from "../components/BlogThumb";

export const Blog = () => {
  return (
    <>
      <main>
        <article className="blog__wrapper">
          <div className="inner a">
            <div className="blog__wrapper-copy">
              <h1 className="blog__wrapper-title">blog</h1>
              <h className="blog__wrapper-subtitle">rants, musing and</h>
              <p className="blog__wrapper-subtitle">everything in between</p>
            </div>
          </div>
        </article>
        <BlogThumb />
      </main>
      <Footer />
    </>
  );
};
