import React from "react";
import { Footer } from "../components/Footer";

import "./blog.css";

export const WorkPlay = () => {
  return (
    <>
      <main>
        <article className="blog__wrapper">
          <div className="inner a">
            <div className="blog__wrapper-copy">
              <p className="blog__wrapper__title-post">
                Here you are, wondering why it's looking so mysteriously empty.
                Well, This page is under construction, a work in progress – just
                like life itself.
              </p>
            </div>
          </div>
        </article>
      </main>
      <Footer />
    </>
  );
};
