import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export const Ikea = () => {
  const timelineRef = useRef(null);
  const ikeaRef = useRef(null);

  useEffect(() => {
    timelineRef.current = gsap
      .timeline({ paused: true })
      .to("#eye", {
        scaleY: 0,
        transformOrigin: "center center",
        yoyo: true,
        duration: 0.7,
        repeat: 3,
        ease: "power1.inOut",
      })
      .to(
        "#right_arm",
        {
          rotation: 10,
          transformOrigin: "top center",
          yoyo: true,
          duration: 0.7,
          repeat: 3,
          ease: "power1.inOut",
        },
        "<"
      );

    return () => timelineRef.current.kill();
  }, []);

  useEffect(() => {
    const handleEnter = () => {
      timelineRef.current.play();
    };

    const handleLeave = () => {
      timelineRef.current.pause();
    };

    let ikea = ikeaRef.current;

    ikea.addEventListener("pointerenter", handleEnter);
    ikea.addEventListener("pointerleave", handleLeave);

    return () => {
      ikea.removeEventListener("pointerenter", handleEnter);
      ikea.removeEventListener("pointerleave", handleLeave);
    };
  }, []);

  return (
    <span className="hero__based-content-overlay" ref={ikeaRef}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="ikea"
          viewBox="0 0 213.53 271.15"
          role="img"
          alt=""
        >
          <g id="right_arm">
            <path
              d="M191.98 93.79s-6.86 37.35-42.7 21.75l1.43 25.92s39.23 16.92 53.1-24.85"
              className="cls-1"
            />
            <path
              d="M192.14 117.3c2.35-2.25 6.18-6.45 7.57-15.72"
              className="cls-1"
            />
          </g>
          <path
            id="torso"
            d="m151.38 117.3-1.96-35.65h25.87c1.23 0 2.22-.99 2.22-2.22 0-39.61-32.11-71.71-71.71-71.71h-4.63c-13.4 0-25.75 4.53-35.59 12.14-16.19 12.52-24.24 32.9-21.8 53.22l3.61 30.15 5.12 160.05H171.93l-13.22-13.08-1.72-31.23-4.27-77.5"
            className="cls-1"
          />
          <path id="leg_path" d="m112.81 195.36 1.31 67.92" className="cls-1" />
          <path
            id="smile"
            d="M73.36 39.6s-20.39 56.1 76.72 54.14"
            className="cls-1"
          />
          <circle id="eye" cx="104.31" cy="44.35" r="3.32" />
          <path
            id="left_arm"
            d="M49.17 103.23s-41.44-1.4-40.13 30.94 41.49 36.52 41.49 36.52-24.93 2.92-19.94 15.27 21.38 2.62 21.38 2.62"
            className="cls-1"
          />
        </svg>
      </div>
      <span>IKEA,</span>
    </span>
  );
};
