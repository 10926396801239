import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export const Fika = () => {
  const fikaRef = useRef(null);
  const masterTimelineRef = useRef(null);

  useEffect(() => {
    masterTimelineRef.current = gsap.timeline({ paused: true });
    const torsoKanelbulleTimeline = gsap.timeline();
    const kanelbulleFlyTimeline = gsap.timeline();
    const lookupTimeline = gsap.timeline();
    const mouthTimeline = gsap.timeline({
      onStart: () => {
        gsap.set("#Mouth", { opacity: 0 });
        gsap.set("#MouthCircle", { opacity: 1 });
      },
      onComplete: () => {
        gsap.set("#Mouth", { opacity: 1 });
        gsap.set("#MouthCircle", { opacity: 0 });
      },
    });
    const blinkingTimeline = gsap.timeline({ repeat: -1, repeatDelay: 1.5 });

    // 1. The torso and kanelbulle animate up from the cup.
    torsoKanelbulleTimeline.fromTo(
      "#Torso, #Kanelbulle, #Head, #Hair, #Nose, #Mouth, #Eyes",
      {
        opacity: 0,
        y: 100,
        scaleY: 0.8,
      },
      {
        opacity: 1,
        duration: 0.5,
        scaleY: 1,
        y: 0,
        transformOrigin: "50% 50%",
        ease: "back.out(2)",
      }
    );

    // 2. Kanelbulle flies above the head and then lands again in the arms.
    kanelbulleFlyTimeline
      .to("#Kanelbulle", {
        duration: 0.5,
        y: "-30",
        rotation: "random(-10, -5)",
        ease: "sine.out",
      })
      .to("#Kanelbulle", {
        duration: 0.4,
        y: "=+30",
        rotation: "random(+10, +5)",
        ease: "power3.out",
      });

    // // 3 lookup
    lookupTimeline
      .to("#Eyes", {
        duration: 0.3,
        y: "-3",
        ease: "power3.out",
      })
      .to("#Eyes", {
        duration: 0.3,
        y: "=+3",
        ease: "power3.out",
      });

    // // 4 Mouth
    mouthTimeline.to("#Mouth", {
      duration: 0.3,
      ease: "power3.inOut",
    });

    // // 5. Blinking
    blinkingTimeline.to("#Eyes", {
      scaleY: 0,
      transformOrigin: "center center",
      duration: 0.5,
      yoyo: true,
      repeat: 1,
    });

    masterTimelineRef.current
      .add(torsoKanelbulleTimeline)
      .add(kanelbulleFlyTimeline, "-=0.5")
      .add(mouthTimeline, "-=0.5")
      .add(lookupTimeline, "-=0.5")
      .add(blinkingTimeline, "+=0.2");

    return () => {
      masterTimelineRef.current.kill();
    };
  }, []);

  useEffect(() => {
    const handleEnter = () => {
      masterTimelineRef.current.play();
    };

    const handleLeave = () => {
      masterTimelineRef.current.pause();
    };

    let appElement = fikaRef.current;

    appElement.addEventListener("pointerenter", handleEnter);
    appElement.addEventListener("pointerleave", handleLeave);

    return () => {
      appElement.removeEventListener("pointerenter", handleEnter);
      appElement.removeEventListener("pointerleave", handleLeave);
    };
  }, []);

  return (
    <span className="hero__based-content-overlay" ref={fikaRef}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 229.26 291.4"
          role="img"
          width="230"
          height="230"
          alt=""
        >
          <ellipse
            id="Opening"
            cx="87.72"
            cy="145.48"
            className="cls-1"
            rx="85.17"
            ry="15.95"
          />
          <g id="Kanelbulle">
            <path
              d="M122.56 13.08s12.21 15.68 8.82 26.95S30.77 53.76 30.77 53.76c-1.85-9.67-2.88-18.88 2.23-24.58l89.56-16.1z"
              className="cls-1"
            />
            <path
              d="M84.32 22.34s25.16-4.88 16.68-8.47-42.05 5.65-38.61 16.68 60.69-8.25 60.17-17.47c-.51-9.22-52.62-11.53-85.48 12.6-30.51 22.4 52.39 16.54 88.02 1.4a6.8 6.8 0 0 1 9.29 4.67l2.54 10.66a11.57 11.57 0 0 1-6.46 13.2c-14.22 6.51-45.53 16.83-88.9 8.27a13.88 13.88 0 0 1-10.79-10.12c-1.19-4.63-1.84-11.12-.28-19.41"
              className="cls-1"
            />
            <path
              d="m34.71 20.85-1.8 5.87L39.23 31l5.19-7.33z"
              className="cls-1"
            />
            <path
              d="m59.88 9.9.79 6.21 7.22-1.13 1.13-6.66z"
              className="cls-1"
            />
            <path
              d="M109.43 14.53v4.06l4.51 2.26 4.07-6.32z"
              className="cls-1"
            />
            <path d="M76.92 12.21v7.4l7.79-1.92-.68-6.21z" className="cls-1" />
            <path d="M55.37 27.84v6.1l6.65.79 3.73-6.89z" className="cls-1" />
            <path
              d="m107.51 3.92-.45 5.41 4.96.91 3.96-6.21z"
              className="cls-1"
            />
          </g>
          <path
            id="Torso"
            d="M41.29 108.41h87.5c.17-15.59-6.2-24.27-15.82-33.62s-2.3-20.56 14.79-7.52c35.43 27.02 5.38 91.71 5.38 91.71s-4.69 3.12-45.43 2.46c-40.38-.65-50.49-3.1-50.49-3.1S6.89 94.29 42.31 67.27c17.09-13.04 24.4-1.83 14.79 7.52s-15.99 18.03-15.81 33.62"
            className="cls-1"
          />
          <path
            id="Head"
            d="M85.04 147.68c-24.49 0-44.35-15.71-44.35-35.1s19.86-35.1 44.35-35.1 44.35 15.71 44.35 35.1-19.86 35.1-44.35 35.1"
            className="cls-1"
          />
          <path
            id="Hair"
            d="M42.86 101.72s41.28 3.58 49.28-19.52c0 0 7.42 24.06 32.4 14.41"
            className="cls-1"
          />
          <path
            id="Nose"
            d="M87.07 128.33H83c-3.02 0-5.38-2.99-5.04-6.41l1.14-11.5c.29-2.92 2.46-5.12 5.04-5.12h1.8c2.58 0 4.75 2.21 5.04 5.12l1.14 11.5c.33 3.42-2.02 6.41-5.05 6.41z"
            className="cls-1"
          />
          <path
            id="Mouth"
            d="M91.58 132.59a6.54 6.54 0 0 1-13.08 0"
            className="cls-1"
          />
          <path
            id="MouthCircle"
            d="M80.5 136.13a4.5 4.5 0 1 1 9 0 4.5 4.5 0 1 1-9 0"
            className="cls-1"
            style={{ opacity: 0 }}
          />
          <g id="Eyes">
            <circle cx="62.54" cy="108.41" r="2.15" />
            <circle cx="107.54" cy="108.41" r="2.15" />
          </g>
          <path
            id="Cup"
            d="M179 164.66c-2.07 0-4.11.14-6.11.43v-19.61H2.55v66.41a75.44 75.44 0 0 0 75.44 75.43h19.47a75.39 75.39 0 0 0 65.15-37.39A44.2 44.2 0 1 0 179 164.66zm0 73.33c-3.56 0-6.96-.64-10.11-1.81 2.59-7.62 4-15.79 4-24.29v-31.5a29.13 29.13 0 1 1 6.11 57.6z"
            className="cls-1"
          />
        </svg>
      </div>
      <span>fika</span>
    </span>
  );
};
