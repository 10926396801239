import Sea from "../assets/sea.webp";
import Dick from "../assets/dick.webp";
import Batman from "../assets/batman.webp";
import Branch from "../assets/branch.webp";
import tinyWalk from "../assets/tinywalk.webp";

export const BlogThumbData = [
  {
    id: 1,
    to: "/blog/wip",
    date: "02.03.2023",
    image: Batman,
    alt: "Batman signal on traffic sign polaroid",
    title: "Well, I'm a work-in-progress portfolio",
    tags: {
      tag: "",
    },
  },
  {
    id: 2,
    to: "/blog/focus-trap",
    date: "04.03.2024",
    image: Branch,
    alt: "A polaroid of a Woman holding a branch in front of her face",
    title: "The Focus Trap",
    tags: {
      tag: ["accessibility"],
    },
  },
  {
    id: 3,
    to: "/blog/accessibility-testing",
    date: "22.01.2024",
    image: tinyWalk,
    alt: "Glued blue children farm animals walking on a blue wall",
    title: "Accessing Accessibility Testing",
    tags: {
      tag: ["testing", "accessibility"],
    },
  },
  {
    id: 4,
    to: "/blog/animation-accessibility",
    date: "17.01.2024",
    image: Sea,
    alt: "the ocean in the north polaroid",
    title: "Animating Accessibility: A Conditional Conundrum",
    tags: {
      tag: ["accessibility", "animation"],
    },
  },
  {
    id: 5,
    to: "/blog/accessibility",
    date: "27.11.2023",
    image: Dick,
    alt: "Don't be a dick t-shirt polaroid",
    title: "Accessibility: An Essential Priority",
    tags: {
      tag: ["accessibility"],
    },
  },
];
