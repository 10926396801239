import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import grumpyDino from "../assets/shadow.webp";
import gsap from "gsap";

import "./header.css";

const navLinks = [
  {
    name: "home",
    path: "/",
  },
  {
    name: "about",
    path: "/about",
  },
  {
    name: "work.play",
    path: "/workplay",
  },
  {
    name: "blog",
    path: "/blog",
  },
];

const useDelayedUnmount = (shouldRender, delay = 0) => {
  const [shouldRenderAfterDelay, setShouldRenderAfterDelay] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (shouldRender) {
      clearTimeout(timerRef.current);
      setShouldRenderAfterDelay(true);
    } else {
      timerRef.current = setTimeout(() => {
        setShouldRenderAfterDelay(false);
      }, delay);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [shouldRender, delay]);

  return shouldRenderAfterDelay;
};

export const Header = ({ menuOpen, setMenuOpen }) => {
  const menuRef = useRef({});
  const dinoRef = useRef({});
  const topRef = useRef();
  const middleRef = useRef();
  const bottomRef = useRef();

  const animation = useRef(null);

  const shouldRenderMenu = useDelayedUnmount(menuOpen, 500);

  useEffect(() => {
    animation.current = gsap.timeline({ paused: true, reversed: true });

    if (menuOpen) {
      if (
        menuRef.current &&
        dinoRef.current &&
        topRef.current &&
        middleRef.current &&
        bottomRef.current
      ) {
        animation.current
          .from(menuRef.current, {
            y: -200,
            transformOrigin: "100% 0%",
            skewY: 55,
            opacity: 0,
            duration: 0.5,
            ease: "elastic.inOut",
          })
          .to(middleRef.current, { opacity: 0, duration: 0.01 }, 0)
          .to(
            topRef.current,
            { y: 5.5, duration: 0.01, ease: "elastic.inOut" },
            0
          )
          .to(
            bottomRef.current,
            { y: -6, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(
            topRef.current,
            { rotation: 135, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(
            bottomRef.current,
            { rotation: -135, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .delay(1.2)
          .to(dinoRef.current, { opacity: 1 });
        document.body.style.overflow = "hidden";
      }
    } else {
      if (menuRef.current && dinoRef.current) {
        animation.current
          .to(menuRef.current, {
            y: "-100%",
            opacity: 0,
            duration: 0.7,
            ease: "elastic.inOut",
          })
          .to(dinoRef.current, { opacity: 0 }, "<")
          .to(
            topRef.current,
            { rotation: 0, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(
            bottomRef.current,
            { rotation: 0, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(topRef.current, { y: 0, duration: 0.01, ease: "sine.inOut" }, 0)
          .to(
            bottomRef.current,
            { y: 0, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(topRef.current, { y: 0, duration: 0.01, ease: "sine.inOut" }, 0)
          .to(
            bottomRef.current,
            { y: 0, duration: 0.01, ease: "sine.inOut" },
            0
          )
          .to(
            middleRef.current,
            { opacity: 1, duration: 0.01, ease: "sine.inOut" },
            0
          );
        document.body.style.overflow = "";
      }
    }

    if (shouldRenderMenu) {
      // Animation logic for when the menu should be rendered
      animation.current.play();

      const focusableElementsInsideMenu = Array.from(
        menuRef.current.querySelectorAll(
          'a[href], button:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
      );

      const hamburgerButton = document.querySelector(".hamburger");
      const focusableElements = [
        hamburgerButton,
        ...focusableElementsInsideMenu,
      ];

      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      const handleKeyDown = (e) => {
        // Close menu on ESC key
        if (e.key === "Escape") {
          setMenuOpen(false);
        }
        // Check if the TAB key is pressed
        if (e.key === "Tab") {
          // If the SHIFT key is pressed (reverse tab), check if we're at the first element
          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus(); // Move to the last element
              e.preventDefault();
            }
          } else {
            // Normal tab, check if we're at the last element
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus(); // Move to the first element
              e.preventDefault();
            }
          }
        }
      };

      // Add event listener
      document.addEventListener("keydown", handleKeyDown);

      // Remove event listener on cleanup
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      // Animation logic for when the menu should be hidden
      animation.current.reverse();
    }
  }, [menuOpen, setMenuOpen, shouldRenderMenu]);

  const handleMenuClick = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <nav>
      <div className="header__wrapper">
        <div className="header__content">
          <Link to="/" className="header__content-name">
            johanna blom
          </Link>
          <button
            className="hamburger"
            onClick={handleMenuClick}
            aria-label={menuOpen ? "Close menu" : "Open menu"}
            aria-expanded={menuOpen}
          >
            <span ref={topRef}></span>
            <span ref={middleRef}></span>
            <span ref={bottomRef}></span>
          </button>
        </div>
      </div>

      {shouldRenderMenu && (
        <article className="menu" ref={menuRef} aria-hidden={!menuOpen}>
          <div>
            <div className="menu__grid" aria-hidden={!menuOpen}>
              <p className="menu__bold">Johanna Blom</p>
              <ul className="menu__ul">
                {navLinks.map((link) => (
                  <li key={link.path} className="menu__li">
                    <Link
                      to={link.path}
                      className="d"
                      onClick={handleNavLinkClick}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <p className="menu__bold lower">Front.end dev</p>
            </div>
            <div className="menu__fd">
              <div className="menu__contact">
                <p className="small__titlemw  nm mf">say hello</p>
                <span></span>
                <a
                  href="mailto:"
                  onClick={(e) => {
                    window.location.href = "mailto:blomjoha@gmail.com";
                    e.preventDefault();
                  }}
                  className="menu__link"
                >
                  email
                </a>
                <a
                  href="https://github.com/idsintehittapa"
                  className="menu__link"
                  onClick={handleNavLinkClick}
                >
                  git
                </a>
                <a
                  href="https://www.linkedin.com/in/johanna-blom-2419a181/"
                  className="menu__link"
                  onClick={handleNavLinkClick}
                >
                  linkedin
                </a>
                <a
                  href="https://twitter.com/johanna__blom"
                  className="menu__link"
                  onClick={handleNavLinkClick}
                >
                  twitter
                </a>
              </div>
              <div className="grumpy__dino" ref={dinoRef}>
                <img
                  src={grumpyDino}
                  alt="grumpy dino"
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </article>
      )}
    </nav>
  );
};
