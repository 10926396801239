import React from "react";
import { Link } from "react-router-dom";
import grumpyDino from "../assets/shadow.webp";

import "./disclaimer.css";

export const Disclaimer = ({ onClose }) => {
  return (
    <article className="disclaimer__wrapper">
      <div className="disclaimer__content">
        <div className="disclaimer__button-wrapper">
          <button className="disclaimer__button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="disclaimer__text">
          <div>
            <p>Hej! This website is a work in progress.</p>
            <p>Proceed at your own peril.</p>
          </div>
          <div className="disclaimer__link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 8"
              role="img"
              className="disclaimer__arrow"
              alt=""
            >
              <path
                d="M293.15 274.51h34.93v9.43h-34.93z"
                fill="none"
                transform="matrix(.58 0 0 .81 -169.47 -223.3)"
              />
              <path
                d="M254.73 285.01v-2.08h64.51l-9.38-6.76H314l10.83 7.8-10.83 7.8h-4.13l9.38-6.75h-64.51Z"
                transform="matrix(.26 0 0 .34 -65.56 -93.65)"
              />
            </svg>
            <Link to="/blog/wip" onClick={onClose}>
              What does this mean?
            </Link>
          </div>
        </div>
        <div className="grumpy__dino-disclaimer">
          <img
            src={grumpyDino}
            alt="grumpy dino"
            width="100%"
            height="auto"
            decoding="async"
            loading="lazy"
          />
        </div>
      </div>
    </article>
  );
};
