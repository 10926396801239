import React from "react";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import ImBatman from "../assets/ImBatman.mp4";
import { BlogThumbData } from "../data/BlogThumbData";

import "./blog.css";

export const WIP = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/blog" className="back__blog">
                  blog
                </Link>
              </div>
              <p className="blog__wrapper-copy-date">{BlogThumbData[0].date}</p>
              <h1 className="blog__wrapper__title-post">
                {BlogThumbData[0].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  Why is my portfolio still a work-in-progress? Some might argue
                  it doesn't boost my professional image or my goal of reaching
                  senior creative developer status. They might be right. But
                  here's how I see it: I'm all about progress and sharing the
                  lessons learned along the way. Instead of holding out for a
                  perfect portfolio, I'd rather display my incremental
                  achievements and learning moments. This approach gives the
                  portfolio the care it deserves and allows me to receive your
                  valuable feedback on practical solutions, animations, and
                  more. It's a win-win situation!"
                </p>
              </div>
              <div className="blog__copy-text">
                <p>
                  The portfolio's design? Well, that's courtesy of &nbsp;
                  <a href="https://www.prvrt.se">
                    Rodrigo Ribeiro at PRVRT studio
                  </a>
                  . After sending him a spontaneous job application, he kindly
                  suggested that my old portfolio needed a makeover to create
                  that "wow" effect that future employers seek. So, he took it
                  upon himself to craft a brand new portfolio for me. Here it
                  is; a work-in-progress page ready to come to life!
                </p>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={ImBatman}
                  alt="I'm Batman GIF"
                  type="video/mp4"
                  load="lazy"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  loop="true"
                  muted="true"
                  autoPlay="autoplay"
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>
                  "The following is a dramatization inspired by true events...
                  well, sort of:
                </p>
              </div>

              <div className="blog__copy-text-quote">
                <p>
                  "Hello, I'd like to join your studio! Your projects are
                  impressive, and I'm truly amazed. Please, have a look at my
                  portfolio—it's quite nice!"
                  <p>
                    "Fool! Don't you know you can't present <span>that</span> if
                    intend to secure a job at a studio?"
                  </p>
                  <p>"I can't?"</p>
                  <p>
                    "It appears dreadful, utterly useless. But don't worry, I'll
                    design a new portfolio for you!"
                  </p>
                </p>
              </div>

              <div className="blog__copy-text">
                <p>
                  Note: Rodrigo never actually said my old portfolio was
                  terrible. He graciously pointed out it could use some
                  enhancements. If you're in need of an amazing designer, I
                  highly recommend checking out &nbsp;
                  <a href="https://www.prvrt.se">PRVRT</a> and Rodrigo's work.
                </p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/blog" className="back__blog end">
                  How about reading other posts?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
