import React from "react";
import { Link } from "react-router-dom";

import "./aboutThumb.css";

export const AboutThumb = () => {
  return (
    <article className="about__thumb">
      <div className="inner">
        <p className="small__title w">About me</p>
        <p className="bold__quote">I enjoy</p>
        <p className="bold__quote">creating delightful</p>
        <p className="bold__quote">and joyful user experiences</p>
        <p className="bold__quote">for people.</p>
        <div className="terse">
          <svg
            className="right__arrow-about"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 8"
          >
            <path
              d="M293.15 274.51h34.93v9.43h-34.93z"
              fill="none"
              transform="matrix(.58 0 0 .81 -169.47 -223.3)"
            />
            <path
              d="M254.73 285.01v-2.08h64.51l-9.38-6.76H314l10.83 7.8-10.83 7.8h-4.13l9.38-6.75h-64.51Z"
              fill="#ebebeb"
              transform="matrix(.26 0 0 .34 -65.56 -93.65)"
            />
          </svg>
          <p className="about__more">
            All right, that was a tad brief. &nbsp;
            <Link to="/about"> Here's more about me</Link>.
          </p>
        </div>
      </div>
    </article>
  );
};
