import Boomer2 from "../assets/bite.webp";
import gBoomer from "../assets/gboomer.webp";
import cBoomer from "../assets/cboomer.webp";
import sheepLove from "../assets/sheepLove.webp";
import RandomPicture1 from "../assets/IMG_5296.webp";
import RandomPicture2 from "../assets/IMG_7881.webp";
import RandomPicture3 from "../assets/IMG_1227.webp";
import colors from "../assets/colors.webp";
import eye from "../assets/eye.JPG";
import iceland from "../assets/iceland.webp";
import legs from "../assets/legs.webp";
import branch from "../assets/branch.webp";
import tinyWalk from "../assets/tinywalk.webp";
import france from "../assets/france.webp";
import boomer from "../assets/boomer.webp";
import DA from "../assets/DA_top15.webp";
import Al from "../assets/Al.webp";
import Deadwood from "../assets/deadwood.webp";
import Fran from "../assets/Fran.webp";
import carrie from "../assets/carrie.webp";
import self from "../assets/self.webp";
import dick from "../assets/dick.webp";
import batman from "../assets/batman.webp";
import hand from "../assets/hand.webp";
import drying from "../assets/drying.webp";
import wood from "../assets/wood.webp";
import norway from "../assets/Norway.webp";
import yellow from "../assets/yellow.webp";

export const TriviaData = [
  {
    info: "I named my dog after the coolest Galactica Cylon and the best Fangs for hire in Far Cry 5: Boomer!",
    image1: gBoomer,
    alt1: "Border collie Boomer",
    image2: Boomer2,
    alt2: "Puppy Boomer lost a fang",
  },
  {
    info: "Boomer and I can be spotted herding sheep or (un)gracefully canicrossing.",
    image1: sheepLove,
    alt1: "Driving behind sheep in the moutains",
    image2: cBoomer,
    alt2: "Boomer and I canicrossing in the snow",
  },
  {
    info: "I like to take weird pictures of of people holding the skulls of vanquished ancient mythical beasts that once roamed the nordic forests.",
    image1: RandomPicture1,
    alt1: "Person with a skull in fron of their face",
    image2: RandomPicture2,
    alt2: "Person with a skull ontop of their head",
  },
  {
    info: "I get inspired by nature, colors (even though I basically only wear black), culture, and sociological theories.",
    image1: colors,
    alt1: "Pink and yellow house in Spain with blue sky",
    image2: iceland,
    alt2: "Waterfall in Iceland",
  },
  {
    info: "I would love (and be wildly intimidated) to be in the same conversation as Fran Lebowitz and Carrie Fisher.",
    image1: Fran,
    alt1: "Fran Lebowitz by Silja Magg",
    image2: carrie,
    alt2: "Carrie Fisher by Redux",
  },
  {
    info: "I get outside every time I am stuck on a problem.",
    image1: legs,
    alt1: "Somone standning in a forest, only showing their legs",
    image2: branch,
    alt2: "Person holding a branch in front of their face",
  },
  {
    info: "I appreciate a delicate equilibrium—a blend of loose boundaries that grant freedom of choice, yet enough structure to stay on track.",
    image1: tinyWalk,
    alt1: "Glued blue children farm animals walking on a blue wall",
    image2: RandomPicture3,
    alt2: "Person holding on to a neckless",
  },
  {
    info: "I enjoy cosycore working: moving slowly and right, petting the dog, walks/running, gazing out the window, pondering problems, and drinking coffee.",
    image1: france,
    alt1: "Black silage bales on a meadow in France",
    image2: eye,
    alt2: "Black and white photo of a person with a light streak on their right eye",
  },
  {
    info: "I have an undeniable affection for Deadwood, particularly Al Swearengen. He's practically my spirit animal, minus the rough edges, of course.",
    image1: Al,
    alt1: "Al Swearengen",
    image2: Deadwood,
    alt2: "Deadwood",
  },
  {
    info: "I began coding to give life to research findings, and my passion for visualization endures to this day.",
    image1: boomer,
    alt1: "chart of boomer",
    image2: DA,
    alt2: "chart of Darwin Awards",
  },
  {
    info: "I'm quite taken with the idea that the web is for everyone. Your webpage should have accessibility as a fundamental priority. Being considerate isn't just polite; it's essential.",
    image1: self,
    alt1: "backlight black and white image of me sad girl style",
    image2: dick,
    alt2: "person with light leaks instead of face and a shirt saying don't be a dick",
  },
  {
    info: "I excel at task organization.",
    image1: batman,
    alt1: "Traffic sign with houses with an added Batman signal sticker to it",
    image2: hand,
    alt2: "person hand with another toy hand stuck to one of it's fingers",
  },
  {
    info: "Caught up in the Designer-Dev-User dynamic, I quite enjoy occupying the middle ground—making things look good while making sure they work.",
    image1: drying,
    alt1: "Polaroid of laundry drying outside of a window in Rome",
    image2: wood,
    alt2: "Tree trunk someone carved a huge face onto",
  },
  {
    info: "I strive to follow the 'The Devil is in the Details' code of conduct.",
    image1: norway,
    alt1: "Black and white picture silhouette of people in front of a waterfall by the road",
    image2: yellow,
    alt2: "Zommed in yellow plants",
  },
];
