import React from "react";

import { Hero } from "../components/Hero";
import { WorkPlayThumb } from "../components/WorkPlayThumb";
import { AboutThumb } from "../components/AboutThumb";
import { Footer } from "../components/Footer";
import { Trivia } from "../components/Trivia";

export const LandingPage = () => {
  return (
    <>
      <main>
        <Hero />
        <WorkPlayThumb />
        <AboutThumb />
        <Trivia />
      </main>
      <Footer />
    </>
  );
};
