import React from "react";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import accessibilityTree from "../assets/accessibilityTree1.webp";
import { BlogThumbData } from "../data/BlogThumbData";

import "./blog.css";

export const AccessibilityEssential = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/blog" className="back__blog">
                  blog
                </Link>
              </div>
              <p className="blog__wrapper-copy-date">{BlogThumbData[4].date}</p>

              <h1 className="blog__wrapper__title-post">
                {BlogThumbData[4].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  I'm quite taken with the idea that the web is for everyone.
                  Your webpage should have accessibility as a fundamental
                  priority. Being considerate isn't just polite; it's essential.
                  Naturally, my own work needs to address this too. I am doing{" "}
                  <a href="https://practical-accessibility.today/">
                    Sara Soueidan's Practical Accessibility course{" "}
                  </a>
                  . She has so far been talking about accessibility 101: WCAG,
                  screen readers, and the <span>accessibility tree</span>.
                </p>
              </div>

              <div className="blog__copy-text">
                <p>
                  The accessibility tree, in a nutshell, is a digital catalog of
                  webpage elements, each carrying vital accessibility info. It's
                  not a twin of the DOM tree; they're more like neighbors who
                  occasionally chat over the fence depending on the topic. You
                  can easily inspect it in the browser DevTools (open Inspect,
                  go to Elements and select Accessibility).
                </p>
              </div>

              <div className="blog__copy-text">
                <p>
                  Let's get straight to the point here: my folio had issues. The{" "}
                  <code>article</code>, showing up uninvited, and the missing{" "}
                  <code>button</code> to handle the menu? That's a recipe for
                  confusion. For screen readers and keyboard navigators, this is
                  like a plot twist in a movie that doesn't make sense. Bottom
                  line: Interactive elements, like menus, need clear buttons for
                  opening and closing, no exceptions. How did I fix it? Well,{" "}
                  <code>aria-label</code>, <code>aria-expanded</code>, and{" "}
                  <code>aria-hidden</code> for starters.
                </p>
              </div>

              <div className="blog__copy-asset">
                <img
                  src={accessibilityTree}
                  alt="accessibility tree for folio"
                  load="lazy"
                  width="100%"
                  height="auto"
                />
              </div>

              <div className="blog__copy-text">
                <p>
                  I applied <code>aria-expanded=menuOpen</code> to the{" "}
                  <code>article</code> responsible for the menu, ensuring it
                  behaved as intended. To maintain order, I introduced a
                  conditional rendering{" "}
                  <code>menuOpen && aria-hidden=menuOpen</code>, guaranteeing
                  the article's visibility only when the menu was open. Lastly,
                  I incorporated{" "}
                  <code>aria-label=menuOpen ? "Close menu" : "Open menu"</code>{" "}
                  for that finishing touch. Problem solved.
                </p>
              </div>
              <div className="blog__copy-text">
                <p>It’s not perfect, but it’s definitely better.</p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/blog" className="back__blog end">
                  How about reading other posts?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
