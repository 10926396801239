import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import noTrap from "../assets/noTrap.mp4";
import withTrap from "../assets/withTrap.mp4";

import { BlogThumbData } from "../data/BlogThumbData";

import "./blog.css";

export const FocusTrap = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/blog" className="back__blog">
                  blog
                </Link>
              </div>
              <p className="blog__wrapper-copy-date">{BlogThumbData[1].date}</p>

              <h1 className="blog__wrapper__title-post">
                {BlogThumbData[1].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  TIL about the focus trap (hats off to the person coming up
                  with that name). Now, you might be wondering what is a focus
                  trap. Well, it’s the proper management of ensuring when a
                  component or popup is open, keyboard navigation is confined
                  within its bounds. Preventing this from happening{" "}
                  <span role="img" alt="Index finger pointing downward">
                    👇🏻
                  </span>
                </p>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={noTrap}
                  type="video/mp4"
                  load="lazy"
                  autoPlay="autoplay"
                  controls="controls"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  muted={true}
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>
                  Adding a focus trap prevents users from inadvertently tabbing
                  to elements outside of the modal improving overall user
                  experience, and potentially boosting engagement and retention
                  on our website + it is a crucial aspect of creating
                  accessibility. Making this happen instead{" "}
                  <span role="img" alt="Index finger pointing downward">
                    👇🏻
                  </span>
                </p>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={withTrap}
                  type="video/mp4"
                  load="lazy"
                  autoPlay="autoplay"
                  controls="controls"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  muted={true}
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>Neat, huh?! Let me break the focus trap down to you!</p>
                <ol className="blog__ol">
                  <li className="blog__list">
                    Collect all elements within the menu that can receive
                    keyboard focus within <code>focusableElements</code>. For
                    the folio, this includes links <code>(a[href])</code>
                    ,buttons that are not disabled{" "}
                    <code>(button:not([disabled]))</code>, other elements with a
                    tabindex that doesn't exclude them from the tab order
                    <code>([tabindex]:not([tabindex=“-1”]))</code>, and the{" "}
                    <code>hamburger button</code>.
                  </li>
                  <li className="blog__list">
                    The <code>first</code> and <code>last</code>{" "}
                    <code>focusableElements</code> are defined inside the{" "}
                    <code>array</code>, these are important for managing the
                    focus trap because they determine the boundaries of keyboard
                    navigation within the menu.
                  </li>{" "}
                  <li className="blog__list">
                    A <code>handleKeyDown</code> function is defined to handle
                    keyboard events. <span>Escape Key</span>: If the Escape key
                    is pressed, the menu is closed by calling{" "}
                    <code>setMenuOpen(false)</code>. <span>Tab Key</span>:
                    determines the direction of navigation (forward or backward
                    through the focusable elements). If the active element is
                    the first (or last) and the user attempts to tab out of the
                    sequence, focus is moved to the last (or first) focusable
                    element, respectively. Creating a loop, or{" "}
                    <span>"trap"</span>, keeping the keyboard focus within the
                    menu.
                  </li>
                  <li className="blog__list">
                    The{" "}
                    <code>
                      document.addEventListener("keydown", handleKeyDown)
                    </code>{" "}
                    adds the <code>handleKeyDown</code> function as an event
                    listener for the <code>'keydown'</code> event on the entire
                    document. This means that any key presses while the menu is
                    open will be handled by this function.
                  </li>{" "}
                  <li className="blog__list">
                    The <code>return</code> statement defines a cleanup function
                    that removes the keydown event listener when the component
                    unmounts or before the effect runs again.
                  </li>{" "}
                </ol>
              </div>

              <div
                className="blog__copy-asset"
                role="complementary"
                aria-label="custom hook code implementation example"
              >
                <SyntaxHighlighter language="javascript" style={prism}>
                  {`    if (shouldRenderMenu) {
      // Animation logic for when the menu should be rendered
      animation.current.play();

      const focusableElementsInsideMenu = Array.from(
        menuRef.current.querySelectorAll(
          'a[href], button:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
      );

      const hamburgerButton = document.querySelector(".hamburger");
      const focusableElements = [
        hamburgerButton,
        ...focusableElementsInsideMenu,
      ];

      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      const handleKeyDown = (e) => {
        // Close menu on ESC key
        if (e.key === "Escape") {
          setMenuOpen(false);
        }
        // Check if the TAB key is pressed
        if (e.key === "Tab") {
          // If the SHIFT key is pressed (reverse tab), check if we're at the first element
          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus(); // Move to the last element
              e.preventDefault();
            }
          } else {
            // Normal tab, check if we're at the last element
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus(); // Move to the first element
              e.preventDefault();
            }
          }
        }
      };

      // Add event listener
      document.addEventListener("keydown", handleKeyDown);

      // Remove event listener on cleanup
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      // Animation logic for when the menu should be hidden
      animation.current.reverse();
    }uldRenderAfterDelay;
};
`}
                </SyntaxHighlighter>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/blog" className="back__blog end">
                  How about reading other posts?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
