import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import { BlogThumbData } from "../data/BlogThumbData";
import menuWrong from "../assets/menu__wrong.mp4";
import menuRight from "../assets/menu__right.mp4";

import "./blog.css";

export const AnimatingAccessibility = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/blog" className="back__blog">
                  blog
                </Link>
              </div>
              <p className="blog__wrapper-copy-date">{BlogThumbData[3].date}</p>
              <h1 className="blog__wrapper__title-post">
                {BlogThumbData[3].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  To ensure my portfolio's accessibility, I needed to manage the
                  menu's presence in the DOM and the accessibility tree. React's
                  conditional rendering, driven by if/else statements, handles
                  this logic beautifully. But here's the conundrum: How do you
                  bestow an animation upon an element that disappears from the
                  DOM when React decides it's time to hide it? This challenge
                  was the root cause of why my GSAP menu animation ‘broke’.
                </p>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={menuWrong}
                  type="video/mp4"
                  load="lazy"
                  autoPlay="autoplay"
                  controls="controls"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  muted={true}
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>
                  So, what was the solution? After some research, I discovered a
                  handful of options. In the end I decided to implementing a
                  custom hook, <code>useDelayedUnmount</code>, which postpones
                  the unmounting of the menu <code>article</code> element,
                  allowing the animation to completion before the elements is
                  removed from the DOM.
                </p>
              </div>

              <div className="blog__copy-text">
                <p>
                  Now, let's dive into the custom hook's workings. It takes two
                  parameters: <code>shouldRender</code> (a <code>boolean</code>{" "}
                  controlling rendering) and <code>delay</code> (an optional
                  waiting period before unmounting). When{" "}
                  <code>shouldRender</code> is set to <code>true</code>, the
                  hook promptly renders the <code>article</code> element. In
                  contrast, when <code>shouldRender</code> is <code>false</code>
                  , it patiently awaits the specified delay <code>delay</code>{" "}
                  before unmounting the element. This ensures, that the
                  animations have their moment to shine. The hook returns a{" "}
                  <code>shouldRenderAfterDelay boolean</code>, dictating when
                  rendering occurs{" "}
                  <span role="img" alt="Index finger pointing downward">
                    👇🏻
                  </span>
                </p>
              </div>

              <div
                className="blog__copy-asset"
                role="complementary"
                aria-label="custom hook code implementation example"
              >
                <SyntaxHighlighter language="javascript" style={prism}>
                  {`const useDelayedUnmount = (shouldRender, delay = 0) => {
  const [shouldRenderAfterDelay, setShouldRenderAfterDelay] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (shouldRender) {
      clearTimeout(timerRef.current);
      setShouldRenderAfterDelay(true);
    } else {
      timerRef.current = setTimeout(() => {
        setShouldRenderAfterDelay(false);
      }, delay);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [shouldRender, delay]);

  return shouldRenderAfterDelay;
};
`}
                </SyntaxHighlighter>
              </div>

              <div className="blog__copy-text">
                <p>
                  But how does this really work then you ask? Let me break it
                  down
                </p>
                <ol className="blog__ol">
                  <li className="blog__list">
                    <code>shouldRenderMenu</code>, decides whether to render the
                    menu based on the menu state (<code>true</code> or{" "}
                    <code>false</code>).
                  </li>
                  <li className="blog__list">
                    When the menu should appear, the GSAP animation make it
                    visible and come to life.
                  </li>{" "}
                  <li className="blog__list">
                    When it's time for the menu to disappear, the GSAP animation
                    is reversed and the <code>delay</code> ensures that the menu
                    remains visible for the transition to complete before the
                    element is removed from the DOM object.
                  </li>
                </ol>
              </div>

              <div className="blog__copy-asset">
                <video
                  src={menuRight}
                  type="video/mp4"
                  load="lazy"
                  autoPlay="autoplay"
                  controls="controls"
                  width="100%"
                  height="auto"
                  playsInline="playsinline"
                  muted={true}
                >
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="blog__copy-text">
                <p>
                  If you find any bugs, don't tell me (yet) I need a vaction
                  from this.
                </p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/blog" className="back__blog end">
                  How about reading other posts?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
