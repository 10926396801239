import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export const Abba = () => {
  const abbaRef = useRef(null);
  const abbaTimelineRef = useRef(null);

  useEffect(() => {
    abbaTimelineRef.current = gsap
      .timeline({ paused: true })
      .to("#note", {
        y: "random(-50, 1, 100)",
        rotate: 40,
        ease: "ease-in",
        yoyo: true,
        duration: 1.7,
        repeat: 2,
      })
      .to(
        "#note-2",
        {
          x: "random(100, 3, 1)",
          rotate: 2,
          yoyo: true,
          duration: 0.7,
          repeat: 3,
          ease: "power1.inOut",
        },
        "<"
      );

    return () => abbaTimelineRef.current.kill();
  }, []);

  useEffect(() => {
    const handleEnter = () => {
      abbaTimelineRef.current.play();
    };

    const handleLeave = () => {
      abbaTimelineRef.current.pause();
    };

    let abba = abbaRef.current;

    abba.addEventListener("pointerenter", handleEnter);
    abba.addEventListener("pointerleave", handleLeave);

    return () => {
      abba.removeEventListener("pointerenter", handleEnter);
      abba.removeEventListener("pointerleave", handleLeave);
    };
  }, []);

  return (
    <span className="hero__based-content-overlay" ref={abbaRef}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="abba"
          viewBox="0 0 233.74 291.78"
          role="img"
          alt=""
        >
          <g id="arms">
            <rect
              width="89.1"
              height="24.44"
              x="-.1"
              y="173.25"
              className="cls-1"
              rx="12.22"
              ry="12.22"
              transform="rotate(-45 44.444 185.47)"
            />
            <rect
              width="89.1"
              height="24.44"
              x="116.1"
              y="172.07"
              className="cls-1"
              rx="12.22"
              ry="12.22"
              transform="rotate(-135 160.648 184.291)"
            />
          </g>
          <g id="feet">
            <path
              d="M89.23 259.31H71.15l-22.07 22.58c-5.53 5.72-2.38 8.38 2.81 8.38h29.15c4.52 0 8.19-3.21 8.19-7.18V259.3ZM115.86 259.31h18.08l22.07 22.58c5.53 5.72 2.38 8.38-2.81 8.38h-29.15c-4.52 0-8.19-3.21-8.19-7.18V259.3Z"
              className="cls-1"
            />
          </g>
          <path
            id="torso"
            d="M130.08 147.74H75c-9.42 0-17.27 7.19-18.1 16.57l-11.54 96.77c0 18.29 114.35 18.29 114.35 0l-11.54-96.77c-.83-9.38-8.68-16.57-18.1-16.57Z"
            className="cls-1"
          />
          <path
            id="ribbon"
            d="M158.92 257.03 55.33 177.06l1.39-11.64c.63-7.07 5.24-12.89 11.5-15.34l85.85 66.29 4.85 40.66Z"
            className="cls-1"
          />
          <ellipse
            id="head"
            cx="102.55"
            cy="103.11"
            className="cls-1"
            rx="68.57"
            ry="54.26"
          />
          <g id="earings">
            <circle cx="33.98" cy="139.54" r="6.33" className="cls-1" />
            <path d="M33.98 105.07v28.14" className="cls-1" />
            <circle cx="171.11" cy="139.54" r="6.33" className="cls-1" />
            <path d="M171.11 105.07v28.14" className="cls-1" />
          </g>
          <path
            id="crown"
            d="m129.95 32.02-6.35 7.78-8.15-9.98-12.9-15.8-12.91 15.8-8.15 9.98-6.35-7.78-11.09-13.58v39.77h77V18.44l-11.1 13.58z"
            className="cls-1"
          />
          <path
            id="hair"
            d="M172.08 55.88c-2.02 0-3.98.3-5.82.85.02-.32.05-.64.05-.97 0-10.3-8.35-18.65-18.65-18.65-6.6 0-12.39 3.44-15.7 8.62-2.56-2.92-6.3-4.77-10.49-4.77-4.85 0-9.12 2.48-11.62 6.23a12.229 12.229 0 0 0-7.87-2.86c-5.55 0-10.23 3.69-11.75 8.74-2.16-4.73-6.92-8.02-12.46-8.02-5.03 0-9.41 2.71-11.8 6.75-3.74-4.13-9.13-6.75-15.15-6.75-11.29 0-20.45 9.16-20.45 20.45 0 2.48.46 4.84 1.27 7.04-.42-.03-.84-.06-1.27-.06-9.83 0-17.8 7.97-17.8 17.8s7.97 17.8 17.8 17.8 17.8-7.97 17.8-17.8c0-1.6-.23-3.14-.63-4.62 1.07.17 2.16.29 3.27.29 9.58 0 17.6-6.6 19.82-15.49 2.08 1.27 4.51 2.01 7.13 2.01 6.41 0 11.77-4.4 13.28-10.34 2.03 3.99 6.16 6.73 10.94 6.73 3.76 0 7.12-1.7 9.37-4.36 2.54 2.68 6.13 4.36 10.12 4.36s7.35-1.57 9.87-4.09c3.18 5.74 9.29 9.63 16.32 9.63 1.42 0 2.79-.17 4.12-.47-.09.79-.15 1.58-.15 2.4 0 11.29 9.16 20.45 20.45 20.45s20.45-9.16 20.45-20.45-9.16-20.45-20.45-20.45Z"
            className="cls-1"
          />
          <path
            id="nose"
            d="M105.69 127.45H99.4c-4.68 0-8.31-4.63-7.79-9.91l1.76-17.78c.45-4.51 3.8-7.92 7.79-7.92h2.78c3.99 0 7.34 3.41 7.79 7.92l1.76 17.78c.52 5.28-3.11 9.91-7.79 9.91Z"
            className="cls-1"
          />
          <path
            id="mouth"
            d="M112.66 134.04c0 5.58-4.53 10.11-10.11 10.11s-10.11-4.53-10.11-10.11"
            className="cls-1"
          />
          <g id="eyes">
            <circle cx="67.76" cy="96.65" r="3.32" />
            <circle cx="137.33" cy="96.65" r="3.32" />
          </g>
          <g id="note" className="note">
            <ellipse
              cx="224.4"
              cy="144.73"
              rx="11.13"
              ry="7.1"
              transform="rotate(-45 224.408 144.727)"
            />
            <path d="m228.84 116.04 2.85 22.29" className="cls-1 note" />
          </g>
          <g id="note-2" className="cls-1 note">
            <ellipse
              cx="9.34"
              cy="41.16"
              rx="11.13"
              ry="7.1"
              transform="rotate(-45 9.34 41.161)"
            />
            <path
              d="m16.62 34.76-2.84-22.28L39.57 1.5"
              className="cls-1 note"
            />
            <ellipse
              cx="35.13"
              cy="30.18"
              rx="11.13"
              ry="7.1"
              transform="rotate(-45 35.13 30.179)"
            />
            <path d="m39.57 1.5 2.84 22.28" className="cls-1 note" />
          </g>
        </svg>
      </div>
      <span>ABBA,</span>&nbsp;
    </span>
  );
};
