import React from "react";
import { Link } from "react-router-dom";
import { BlogThumbData } from "../data/BlogThumbData";

import "../pages/blog.css";

export const BlogThumb = () => {
  return (
    <>
      <article className="blog__thumb-posts">
        <ul className="blog__content-grid">
          {BlogThumbData.map(({ id, to, date, image, alt, title, tags }) => (
            <li className="blog__content-list" key={id}>
              <Link to={to} className="blog__link">
                <div>
                  <img
                    srcSet={
                      (`${image}?width=300 300w`, `${image}?width=400 400w`)
                    }
                    alt={alt}
                    className="blog__content-img"
                    loading="lazy"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div>
                  <p className="blog__content-date">{date}</p>
                  <p className="blog__content-title">{title}</p>
                </div>
              </Link>
              <div className="tags">
                {tags.tag &&
                  tags.tag.map((tag, index) => (
                    <button className="tag" key={index} disabled>
                      {tag}
                    </button>
                  ))}
              </div>
            </li>
          ))}
        </ul>
      </article>
    </>
  );
};
