import React from "react";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import axeAwwwards from "../assets/axe.webp";
import axeAwwwardsDetail from "../assets/axeDetail.webp";

import { BlogThumbData } from "../data/BlogThumbData";

import "./blog.css";

export const AccessibilityTesting = () => {
  return (
    <>
      <main>
        <article>
          <div className="inner a">
            <div className="blog__wrapper-copy-post">
              <div className="back__blog-wrapper">
                <Link to="/blog" className="back__blog">
                  blog
                </Link>
              </div>
              <p className="blog__wrapper-copy-date">{BlogThumbData[2].date}</p>

              <h1 className="blog__wrapper__title-post">
                {BlogThumbData[2].title}
              </h1>
              <div className="blog__copy-line">
                <span></span>
              </div>
            </div>

            <div className="blog__copy-wrapper">
              <div className="blog__copy-text">
                <p>
                  If you're wondering what accessibility testing is, don't
                  worry, you're not alone. Accessibility testing is like the
                  digital equalizer of the web, ensuring that everyone,
                  regardless of their abilities, can access and use your
                  website. I learned and started using <span>Axe DevTools</span>{" "}
                  recently. It’s a chrome extension which highlights issues like
                  missing <code>alt</code> text for images, low contrast text,
                  and navigation hurdles that can be challenging for some users.
                  It's like having a proofreader for your website and it’s
                  straightforward, which I appreciate.
                </p>
              </div>

              <div className="blog__copy-asset">
                <img
                  src={axeAwwwards}
                  alt="Axe DevTools extension analyze awwwards.com example"
                  load="lazy"
                  width="100%"
                  height="auto"
                />
              </div>

              <div className="blog__copy-text">
                <p>
                  I ran the test on the{" "}
                  <a href="https://www.awwwards.com/">awwwards</a> site, and
                  this is the list of issued found by <span>Axe DevTools</span>.
                  You can open each issue and get a more detailed view of the
                  issue and a solution.
                </p>
              </div>

              <div className="blog__copy-asset">
                <img
                  src={axeAwwwardsDetail}
                  alt="Axe DevTools detailed awwwards.com view of an issue"
                  load="lazy"
                  width="100%"
                  height="auto"
                />
              </div>

              <div className="blog__copy-text">
                <p>
                  Why one should you care about accessibility testing? It's not
                  just about following the rules; it's about making the web a
                  more inclusive place. Plus, it can save you from potential
                  legal troubles and improve your website's SEO. A win-win!
                </p>
              </div>

              <div className="blog__copy-text">
                <p>
                  How to get started with Axe DevTools? Simply download the
                  extension, open your browser's developer tools, locate the Axe
                  tab, and click Scan ALL of my pages, make sure to have Best
                  Practice On. Small changes can make a big difference.
                </p>
              </div>

              <div className="the__end">
                <p>THE END.</p>
                <div className="blog__copy__small-line">
                  <span></span>
                </div>
                <Link to="/blog" className="back__blog end">
                  How about reading other posts?
                </Link>
              </div>
            </div>
          </div>
        </article>
      </main>

      <Footer />
    </>
  );
};
