import React, { useEffect } from "react";
import Obama from "../assets/obamaJohanna.mp4";
import ObamaPoster from "../assets/obama.webp";

import "./disclaimer.css";

export const PopUp = ({ isOpen, onClose }) => {
  // Use useEffect to manage focus and add keyboard listeners when the component mounts
  useEffect(() => {
    if (isOpen) {
      const modal = document.querySelector(".disclaimer__wrapper");
      const focusables = modal.querySelectorAll("button, video");
      const firstFocusableElement = focusables[0];
      const lastFocusableElement = focusables[focusables.length - 1];

      // Remember the element that was focused before opening the modal
      const previouslyFocusedElement = document.activeElement;

      // Focus the first focusable element
      firstFocusableElement.focus();

      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          onClose();
        } else if (event.key === "Tab") {
          if (event.shiftKey) {
            // Shift + Tab
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus();
              event.preventDefault();
            }
          } else {
            // Tab
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus();
              event.preventDefault();
            }
          }
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        // Return focus to the element that was focused before opening the modal
        if (previouslyFocusedElement) previouslyFocusedElement.focus();
      };
    }
    // Only re-run the effect when `isOpen` changes
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <article className="disclaimer__wrapper">
      <div className="popup__content">
        <div className="popup__wrapper">
          <button className="popup__close" onClick={onClose}>
            X
          </button>
          <video
            className="popup__video"
            controls
            tabIndex="0"
            aria-label="Popup video content"
            preload="metadata"
            poster={ObamaPoster}
          >
            <source src={Obama} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </article>
  );
};
